import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, DatePicker, Modal } from 'antd';
import { ganttAPI } from '../../utils/customGanttPlugin';
import moment from 'moment';
import modalStyles from '../../views/ganttContainer/gantt/modals.module.scss';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { isFeatureOn } from '../../utils/featureUtils';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import {
  getSelectedActivitiesSelector,
  getShowedFiltersSelector
} from '../../redux/slices/ganttSlice';
import GanttFilter from '../GanttFilter';
import GanttOrder from '../GanttOrder';
import * as ganttActions from '../../redux/slices/ganttSlice';
import { setSaveMasterplanFunction } from '../../redux/slices/nonSerializableSlice';
import { GanttToolbar } from '../GanttToolbar';
import { GanttMassiveToolbar } from '../GanttMassiveToolbar';
import './index.css';
import { executeNewToolbarCommand } from './GanttFilterHeaderUtils';
import cloneDeep from 'lodash/cloneDeep';
import { WarningIcon } from '../../icons';
import { MassiveDuplicateComp } from '../GanttMassiveToolbar/icons';
import colors from '../../stylesheets/variables.scss';
import { openNotification } from '../../utils';
import { connect } from 'react-redux';
import ExportPdfModal from '../ExportPdfModal/ExportPdfModal';
import GanttLeftToolbar from '../GanttLeftToolbar/GanttLeftToolbar';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import {
  enableForMassiveSelect,
  transformHourToDays
} from '../../views/ganttContainer/gantt/gantt.helper';
import { createTask } from '../GanttVisualization/GanttVisualization.helper';
import { calendarService } from '../../services';
import { CONSTRAINT_TYPES_SUBMENU_ITEMS } from '../GanttMassiveToolbar/constants';
import useModalBasic from '../../hooks/useModalBasic';
import { getTypeNotification, notifyMessageCustom } from '../../utils';
import { trackingEvent } from '../../analytics';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import {
  getFilterOrder,
  getGeneralFilters
} from '../../redux/selectors/viewsSelector';
import { updateDefaultView } from '../../services/views/viewSync';
import { getGanttLoadingSelector } from '../../redux/slices/ganttSlice';

import ModificationRequestsModal from '../ModificationRequestsModal';
import ModalMessage from '../ExportablePDFMessage/ModalMessage';
import { ExportP6XML } from '../../assets/gantt/ext/Export/ExportP6XML';

const { RangePicker } = DatePicker;

export const GanttFilterHeader = (props) => {
  const {
    selectedActivities,
    ganttObject,
    modalDelMass,
    modalDupMassAddActivities,
    callbackModalDupConfirmMass,
    isGanttLoading
  } = props;

  const [datesVisibility, setDatesVisibility] = useState(false);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [orderVisibility, setOrderVisibility] = useState(false);
  const [ganttLevels, setGanttLevels] = useState([]);
  const dispatch = useDispatch();
  const ganttState = useSelector((state) => state.ganttState);
  const projectState = useSelector((state) => state.projectState);
  const history = useHistory();
  const { permission, saveHandler, t, zoomLevel, dataForColumns } = props;
  const [isSubDropActive, setIsSubDropActive] = useState({ value: false });
  const [modalMetadata, setModalMetadata] = useState({
    visible: false,
    onOkPayload: () => {
      console.log('On ok payload');
    },
    onCancelPayload: () => {
      console.log('On Cancel payload');
    },
    title: 'Modal',
    content: <div>Modal</div>,
    cancelText: 'Cancel',
    okText: 'Ok'
  });
  const [isExportPdfModalVisible, setIsExportPdfModalVisible] = useState(false);
  const [isModalErrorVisible, setIsModalErrorVisible] = useState(false);

  const defaultOrder = useSelector(getFilterOrder);
  const defaultFilters = useSelector(getGeneralFilters);
  const [andFilter, setAndFilter] = useState({
    value: false
  }); /** Saves value to combine through AND/OR the whole filter list */
  const [showedFilters, setShowedFilters] = useState({
    value: defaultFilters
  }); /** Array with active filters */
  const [showedFiltersOrder, setShowedFiltersOrder] = useState({
    value: []
  }); /** Array with active filters */
  const [optionsArray, setOptionsArray] = useState({});
  const [selectedOption, setSelectedOption] = useState({ value: [] });

  /** handle multi select columns */
  const [columnsVisibility, setColumnsVisibility] = useState(false);

  /** handle references to filters */
  const filterRef = useRef(null);
  const datesRef = useRef(null);
  const orderRef = useRef(null);
  const columnsRef = useRef(null);
  const prevShowedFilters = useRef([]);
  /** handle constraint types */
  const [constraintTypes, setConstraintTypes] = useState(
    CONSTRAINT_TYPES_SUBMENU_ITEMS
  );

  const handleHideLeftActionOptions = (action = '') => {
    setFilterVisibility(false);
    setOrderVisibility(false);
    setDatesVisibility(false);
  };

  const handleHideFilters = (target) => {
    const classesToIgnore = [
      'css-1hwfws3',
      'option',
      'placeholder',
      'control',
      'ant-calendar-range-picker-input',
      'ant-calendar-date',
      'optionForFilter',
      'ant-select-dropdown-menu-item'
    ];

    const shouldIgnoreTarget = (target) => {
      if (!target || !target.className) return false;
      return classesToIgnore.some((className) =>
        target.className.includes(className)
      );
    };

    if (target && target.nodeName === 'DIV' && !shouldIgnoreTarget(target)) {
      handleHideLeftActionOptions();
    }
  };

  useOutsideAlerter(filterRef, handleHideFilters);
  useOutsideAlerter(orderRef, handleHideFilters);
  useOutsideAlerter(datesRef, handleHideFilters);
  useOutsideAlerter(columnsRef, handleHideFilters);

  useEffect(() => {
    if (!defaultOrder) return;
    if (!defaultOrder.length) return;
    setShowedFiltersOrder({ value: defaultOrder });
    checkBackgrondOrder({ value: defaultOrder });
  }, [defaultOrder]);

  const saveGantt = () => {
    if (permission !== 'V') {
      saveHandler(true);
    }
  };
  const [valueInput, setValueInput] = useState({ someVal: '' });

  useEffect(() => {
    const activeFilters =
      props.showedFilters.length > 0 ? props.showedFilters : [];
    setShowedFilters({ value: activeFilters });
    checkBackgrondFilter({ value: activeFilters });

    if (
      prevShowedFilters.current.length > 0 &&
      props.showedFilters.length === 0
    ) {
      forceCleanFilters();
    }

    prevShowedFilters.current = props.showedFilters;
    updateDefaultView(isGanttLoading);
  }, [props.showedFilters]);

  useEffect(() => {
    if (!saveHandler) return;

    dispatch(setSaveMasterplanFunction(saveGantt));
  }, [saveHandler]);

  const saveGanttAsync = async () => {
    if (permission !== 'V') {
      await saveHandler();
    }
  };

  useEffect(() => {
    dispatch(ganttActions.setIsRangeFiltered({ boolean: false, range: [] }));

    const gantt = props.ganttObject;

    gantt.attachEvent('onGanttDetectedLevelsChange', (newDetectedLevels) => {
      setGanttLevels([...new Set(newDetectedLevels)]);
    });
    loadCalendars();
  }, []);

  const loadCalendars = async () => {
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    ganttAPI.loadCalendars(calendars.calendar);
  };

  /**
   * This functions changes the visibility of dropdown for filters
   * @param {*} val Boolean, true to show dropdown, and false to hide dropdown
   */
  const handleFilterDropdown = (val) => {
    if (!isSubDropActive.value) {
      setFilterVisibility(val);
    }
    if (isSubDropActive.value && val) {
      setFilterVisibility(val);
    }
  };

  function forceCleanFilters() {
    const gantt = props.ganttObject;
    gantt.isDataFiltered = false;
    const tasks = gantt.getTaskByTime();
    tasks.filter((task) => {
      task.should_be_showed = true;
    });

    gantt.refreshData();
    gantt.render();
    gantt.scrollTo(null, 0);
  }

  function updateShowedFilters(filters) {
    dispatch(ganttActions.setShowedFilters(filters.value));
  }

  function handleModalOk(e) {
    if (modalMetadata.onOkPayload) {
      modalMetadata.onOkPayload();
    }
    updateModalRender();
  }

  function handleModalCancel(e) {
    modalMetadata.visible = false;
    if (modalMetadata.onCancelPayload) {
      modalMetadata.onCancelPayload();
    }
    updateModalRender();
  }

  function updateModalRender() {
    setModalMetadata((prev) => {
      prev = cloneDeep(modalMetadata);
      return prev;
    });
  }

  const addCopyOfActivity = async (activity) =>
    await createTask(
      null,
      'New task',
      activity,
      true,
      projectState,
      () => {},
      () => {},
      ganttAPI
    );

  /** use custom hook for progress alert */
  const message = t('master_plan.dup_warning');
  const contentModalDuplicateMassive = {
    customWidth: 430,
    message: message,
    question_i18: null,
    title_i18: null,
    text_ok_i18: 'confirmation.continue',
    hideCancelButton: false,
    hideOkButton: false
  };
  const { jsxModalDuplicateMass, callbackModalDuplicateMass } = useModalBasic(
    t,
    contentModalDuplicateMassive,
    'DuplicateMass'
  );

  /**
   * This function opens the modal that displays the warning on bulk action of duplicate
   */
  const modalDuplicateMass = (n) => {
    const message = t('master_plan.dup_warning').replace(
      '__n__',
      '<span>' + n + '</span>'
    );

    /** matrix to give css to the number of activities (n) */
    const msgArr = [];
    msgArr.push(message.split('(')[0]);
    msgArr.push(n);
    msgArr.push(message.split(')')[1]);
    callbackModalDuplicateMass &&
      callbackModalDuplicateMass(processDuplicate, msgArr);
  };

  const handleDuplicateMP = async () => {
    callbackModalDupConfirmMass(() => duplicateConfirmed());
  };

  const duplicateConfirmed = () => {
    let contActivitieswithTasks = 0;
    if (selectedActivities.length) {
      selectedActivities.forEach((act) => {
        if (act.tasks && act.tasks.length) {
          contActivitieswithTasks++;
        }
      });
    }
    if (contActivitieswithTasks) {
      modalDuplicateMass(contActivitieswithTasks);
    } else {
      processDuplicate();
    }
  };

  const processDuplicate = async () => {
    if (selectedActivities.length) {
      let countActivitiesWithTasks = 0;
      let countMilestones = 0;
      let flagDuplicate = false;
      let countNewActivities = 0;
      selectedActivities.forEach(async (act) => {
        const actGet = ganttObject.getTask(act.id);
        if (actGet?.type == 'milestone') countMilestones++;
        if (!ganttObject.hasChild(act.id) && actGet?.type != 'milestone') {
          /** only apply to child activitys */
          if (
            (!actGet.tasks && actGet.proplannerId) ||
            (actGet.tasks && !actGet.tasks?.length)
          ) {
            const actToSend = {
              ...actGet,
              is_lookahead: true,
              name: actGet.text,
              has_childs: Boolean(ganttObject.hasChild(actGet.id)),
              id: actGet.proplannerId || actGet.id,
              duration: transformHourToDays(actGet.duration),
              calendarId: actGet.calendar_id,
              tasks: []
            };
            actGet.is_lookahead = true;
            actGet.tasks = [
              {
                name: 'New Task'
              }
            ];
            flagDuplicate = true;
            await addCopyOfActivity(actToSend);
          } else {
            if (actGet.proplannerId) {
              countActivitiesWithTasks++;
            } else {
              countNewActivities++;
            }
          }
        }
      });
      if (countMilestones) {
        openNotification({
          title: t('duplicate_activity_lookahead'),
          description: t('milestone_not_replicable'),
          type: 'info'
        });
      }
      if (flagDuplicate) await saveGanttAsync();
      if (countNewActivities) {
        modalDupMassAddActivities(countNewActivities);
      } else {
        ganttObject.optimizedRender();
        ganttObject.ext.keyboardNavigation.focus({
          type: 'taskCell',
          id: selectedActivities[0].id,
          column: 'progress'
        });
        if (flagDuplicate)
          notifyMessageCustom({
            type: getTypeNotification(MassiveDuplicateComp),
            description: t('massive_notif_duplicate')
          });
      }
    }
  };

  const handleDeleteMP = async () => {
    modalDelMass(selectedActivities);
  };

  const handleConstraintTypes = (value) => {
    if (selectedActivities.length) {
      selectedActivities.forEach(async (act) => {
        const hasChild = ganttObject.hasChild(act.id);
        if (!hasChild) {
          const actGet = ganttObject.getTask(act.id);
          const constraint_type_old = actGet.constraint_type;
          actGet.constraint_type = value;
          actGet.constraint_type_old = constraint_type_old;
        }
      });
      ganttObject.optimizedRender();
    }
  };

  /**
   * This handler should be removed as soon as we replace legacy global Gantt utility functions
   * with a proper Redux-based approach. After this, this logic should be moved
   * back to the GanttToolbar component.
   */
  const handleToolbarCommandDispatched = (command, value) => {
    const commandHandler = {
      IMPORT_SCHEDULE: () => {
        //
        if (props.ganttObject.getTaskByTime()?.length > 0) {
          modalMetadata.title = t('modals.gantt.non_saved_activities.title');
          modalMetadata.okText = t('model_import_validate.ok');
          modalMetadata.cancelText = t('master_plan_calendars.cancel');
          modalMetadata.input = '';
          modalMetadata.content = (
            <div>
              <div className="icon-center">
                <WarningIcon color={colors.brandOrange40} />
              </div>
              <div className="body-center">
                {t('model_import_validate.description') + valueInput.someVal}
              </div>
              <div className="body-center">
                <br />
                {t('model_import_validate.subtext_1')}
                <span style={{ color: '#7DFF8A' }}>
                  {t('model_import_validate.subtext_2')}
                </span>
                {t('model_import_validate.subtext_3')}
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    alignContent: 'center',
                    marginTop: 20
                  }}>
                  {/* <Input value={valueInput.someVal || ''}  style={{ width: '250px' ,height: '30px' }} onChange={(e)=>{
                                setValueInput({someVal: e.target.value})

                            }} /> */}
                  <input
                    defaultValue={modalMetadata.input}
                    name="firstName"
                    onChange={(e) => (modalMetadata.input = e.target.value)}
                  />
                </Row>
              </div>
            </div>
          );
          modalMetadata.visible = true;
          modalMetadata.onOkPayload = () => {
            // updateLocalState(item)
            if (modalMetadata.input === 'IMPORT') {
              modalMetadata.visible = false;
              history.push('/importgantt');
            } else {
              const alertErrorMailExists = {
                title: t('model_import_validate.error'),
                description: '',
                type: 'error'
              };
              openNotification(alertErrorMailExists);
            }
          };
          modalMetadata.onCancelPayload = () => {};
          updateModalRender();
        } else {
          history.push('/importgantt');
        }
      },
      SAVE: () => saveGantt(),
      SHOW_BASELINES_MODAL: () => {
        trackingEvent(
          'saved_baseline_button_selection',
          {
            ...getBasicAmplitudEventProperties()
          },
          AMPLITUDE_SERVICE
        );
        handleConfigClick('base_lines');
      },
      SHOW_WORK_HOURS_MODAL: () => handleConfigClick('hours_work'),
      DATE: () => handleVisibilityFilters(command),
      FILTER: () => handleVisibilityFilters(command),
      ORDER: () => handleVisibilityFilters(command),
      COLUMNS: () => handleVisibilityFilters(command),
      MASSIVE_DELETE: async () => handleDeleteMP(),
      MASSIVE_DUPLICATE: async () => handleDuplicateMP(command),
      MASSIVE_CONSTRAINT_TYPE: () => {
        /** handle state */
        const newData = CONSTRAINT_TYPES_SUBMENU_ITEMS.map((el) => {
          let checked = false;
          if (el.value === value) {
            checked = true;
          }
          return {
            ...el,
            checked: checked
          };
        });
        setConstraintTypes(newData);

        /** handle data */
        handleConstraintTypes(value);
        const gantt = window.to_use_react_gantt;
        gantt.ext.keyboardNavigation.focus({
          type: 'taskCell',
          id: selectedActivities[0].id,
          column: 'constraint_type'
        });
      },
      SHOW_SCHEDULE_MODAL: () => handleConfigClick('show_schedule_updates')
    }[command];

    const handleVisibilityFilters = (command) => {
      const paramFilter = command === 'FILTER' ? !filterVisibility : false;
      const paramOrder = command === 'ORDER' ? !orderVisibility : false;
      const paramDate = command === 'DATE' ? !datesVisibility : false;
      const paramColumns = command === 'COLUMNS' ? !columnsVisibility : false;

      handleFilterDropdown(paramFilter);
      handleOrderDropdown(paramOrder);
      handleDatesDropdown(paramDate);
      setColumnsVisibility(paramColumns);
    };

    const COMMANDS = {
      DOWNLOAD_SCHEDULE_TO_PDF: () => {
        const isNewPdfExportingModalEnabled = isFeatureOn(
          FEATURE_FLAGS.ENABLE_NEW_PDF_EXPORTING_MODAL
        );

        if (!isNewPdfExportingModalEnabled) {
          return;
        }
        setIsExportPdfModalVisible(true);
      },
      DOWNLOAD_SCHEDULE_TO_P6_XML: () => {
        ExportP6XML({ t, projectState });
      }
    };

    const commandAction = COMMANDS[command];
    if (commandAction) {
      commandAction();
      return;
    }

    if (commandHandler) {
      commandHandler();
      return;
    } else if (/^SET_DISPLAY_LEVEL_\d$/.test(command)) {
      value = Number(command.slice(-1));
      command = 'LEVELS';
    } else if (/^DOWNLOAD_SCHEDULE_TO_/.test(command)) {
      value = command.split('_TO_').pop().toLowerCase();
      command = 'DOWNLOAD_SCHEDULE';
    } else if (/^CHANGE_COLOR_FOR_/.test(command)) {
      value = command.split('_FOR_').pop().toLowerCase();
      command = 'CHANGE_COLOR';
    } else if (/^CHANGE_COLOR_ROW_FOR_/.test(command)) {
      value = command.split('_FOR_').pop().toLowerCase();
      command = 'CHANGE_COLOR_ROW';
    }

    executeNewToolbarCommand(command, value);
  };

  const handleDatesDropdown = (val) => {
    setDatesVisibility(val);
  };

  const handleOrderDropdown = (val) => {
    setOrderVisibility(val);
  };

  const handleConfigClick = (opt) => {
    props.handleConfigClick(opt);
  };

  function onRangePickerChange(dates, dateStrings) {
    if (dates[0] && dates[1]) {
      props.ganttObject.isDataFiltered = true;
      dispatch(
        ganttActions.setIsRangeFiltered({ boolean: true, range: dateStrings })
      );
      const tasks = props.ganttObject.getTaskByTime();
      tasks.map((task) => {
        const start = new Date(dateStrings[0]).getTime(); /** startdate */
        const end = new Date(dateStrings[1]).getTime(); /** enddate */
        const taskStart = task.start_date.getTime(); /** startD */
        const taskEnd = task.end_date.getTime(); /** endD */
        if (
          (taskStart >= start && taskEnd <= end) ||
          (taskStart >= start && taskStart <= end) ||
          (start >= taskStart && start <= taskEnd)
        ) {
          task.should_be_showed = true;
        } else {
          task.should_be_showed = false;
        }
      });
    } else {
      dispatch(ganttActions.setIsRangeFiltered({ boolean: false, range: [] }));
      props.ganttObject.isDataFiltered = false;
      props.ganttObject.getTaskByTime().map((t) => {
        t.should_be_showed = true;
      });
    }

    props.ganttObject.refreshData();
    props.ganttObject.render();
    setDatesVisibility(false);
    updateDefaultView();
  }
  const [backgroundColorFilter, setBackgroundColorFilter] = useState({
    show: false,
    style: {},
    counter: 0
  });
  const checkBackgrondFilter = (showedfilters) => {
    if (showedfilters.value) {
      if (
        showedfilters.value.length &&
        showedfilters.value.length != backgroundColorFilter.counter
      ) {
        setBackgroundColorFilter({
          show: true,
          counter: showedfilters.value.length
        });
      } else if (showedfilters.value.length != backgroundColorFilter.counter) {
        setBackgroundColorFilter({
          show: false,
          style: {},
          counter: showedfilters.value.length
        });
      }
    }
  };

  const [backgroundColorOrder, setBackgroundColorOrder] = useState({
    show: false,
    style: {},
    counter: 0
  });
  const checkBackgrondOrder = (showedfilters) => {
    if (showedfilters.value) {
      if (
        showedfilters.value.length &&
        showedfilters.value.length != backgroundColorOrder.counter
      ) {
        setBackgroundColorOrder({
          show: true,
          style: {
            border: '1px solid #2C3421',
            borderRadius: 4,
            width: '90%',
            height: '128%',
            top: '-25%',
            left: '8%',
            position: 'absolute'
          },
          counter: showedfilters.value.length
        });
      } else if (showedfilters.value.length != backgroundColorOrder.counter) {
        setBackgroundColorOrder({
          show: false,
          style: {},
          counter: showedfilters.value.length
        });
      }
    }
  };

  /** props for differents components */
  const ganttFilterProps = {
    toSelectResponsables: props.toSelectResponsables,
    toSelectTags: props.toSelectTags,
    subContracts: props.subContracts,
    t: t,
    checkBackgrondFilter: checkBackgrondFilter,
    gantt: props.ganttObject,
    options: props.optionsForFilter,
    isSubDropActive: isSubDropActive,
    setIsSubDropActive: setIsSubDropActive,
    showedFilters: showedFilters,
    setShowedFilters: updateShowedFilters,
    andFilter: andFilter,
    setAndFilter: setAndFilter,
    optionsArray: optionsArray,
    selectedOption: selectedOption
  };

  const ganttOrderProps = {
    t: t,
    checkBackgrondOrder: checkBackgrondOrder,
    gantt: props.ganttObject,
    options: props.optionsForFilter,
    showedFilters: showedFiltersOrder,
    setShowedFilters: setShowedFiltersOrder
  };

  const ganttDates = () => (
    <div className="gantt-filter-left-dates">
      <RangePicker
        onChange={onRangePickerChange}
        value={
          ganttState.isRangeDateFiltered
            ? [
                moment(ganttState.dateRangeToFilter[0]),
                moment(ganttState.dateRangeToFilter[1])
              ]
            : []
        }
      />
    </div>
  );

  const counterFilters = {
    filter: backgroundColorFilter.counter,
    order: backgroundColorOrder.counter,
    dates: ganttState.isRangeDateFiltered ? 1 : 0
  };

  const isBulkActionsBarFeatureEnabled =
    isFeatureOn(FEATURE_FLAGS.ENABLE_BULK_ACTIONS_BAR) &&
    enableForMassiveSelect();
  return (
    <span>
      <Row
        className="gantt-header-filters"
        onClick={({ target }) => {
          window.to_use_react_gantt &&
            window.to_use_react_gantt.ext &&
            window.to_use_react_gantt.ext.inlineEditors.save();

          if (
            target.nodeName === 'DIV' &&
            !target.classList.contains('css-1hwfws3') &&
            !target.className.includes('option') &&
            !target.className.includes('placeholder') &&
            !target.className.includes('control')
          ) {
            setColumnsVisibility(false);
          }
        }}>
        {/* Filter Toolbar on left side */}
        <GanttLeftToolbar
          t={t}
          onCommandDispatched={handleToolbarCommandDispatched}
          counterFilters={counterFilters}
        />
        {/* Menu of each filter option */}
        <Options
          filterRef={filterRef}
          datesRef={datesRef}
          orderRef={orderRef}
          columnsRef={columnsRef}
          dataForColumns={dataForColumns}
          filterVisibility={filterVisibility}
          orderVisibility={orderVisibility}
          datesVisibility={datesVisibility}
          columnsVisibility={columnsVisibility}
          ganttDates={ganttDates}
          columnsSelector={props.columnsSelector}
          ganttFilterProps={ganttFilterProps}
          ganttOrderProps={ganttOrderProps}
          setColumnsVisibility={setColumnsVisibility}
          t={t}
        />

        {/* Toolbar on rigth side */}
        <GanttToolbar
          gantt={props.ganttObject}
          t={t}
          ganttLevels={ganttLevels}
          zoomLevel={zoomLevel}
          onCommandDispatched={handleToolbarCommandDispatched}
        />
        <Modal
          wrapClassName={`activity-modification-style ${modalStyles['gantt-alert-modal']}`}
          cancelText={modalMetadata.cancelText}
          okText={modalMetadata.okText}
          title=" &nbsp;"
          centered
          width={480}
          visible={modalMetadata.visible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}>
          {modalMetadata.content}
        </Modal>
        <ExportPdfModal
          t={t}
          isSchedule={true}
          isVisible={isExportPdfModalVisible}
          setIsVisible={setIsExportPdfModalVisible}
          handleClose={() => setIsExportPdfModalVisible(false)}
          openFitToOnePageModal={() => setIsModalErrorVisible(true)}
        />
        <ModalMessage
          isShow={isModalErrorVisible}
          setIsShow={setIsModalErrorVisible}
        />
      </Row>
      <div className="toolbar-wrapper">
        {isBulkActionsBarFeatureEnabled ? (
          <GanttMassiveToolbar
            t={t}
            ganttLevels={ganttLevels}
            zoomLevel={zoomLevel}
            onCommandDispatched={handleToolbarCommandDispatched}
            constraintTypes={constraintTypes}
            setConstraintTypes={setConstraintTypes}
            ganttObject={props.ganttObject}
            toSelectResponsables={props.toSelectResponsables}
            toSelectTags={props.toSelectTags}
            subContracts={props.subContracts}
          />
        ) : null}
      </div>
      {/* Duplicate Massive */}
      {jsxModalDuplicateMass}
    </span>
  );
};

const Options = (props) => {
  const {
    filterRef,
    orderRef,
    datesRef,
    columnsRef,
    filterVisibility,
    orderVisibility,
    datesVisibility,
    columnsVisibility,
    ganttDates,
    dataForColumns,
    ganttFilterProps,
    ganttOrderProps,
    t
  } = props;

  return (
    <>
      {filterVisibility && (
        <span ref={filterRef}>
          <GanttFilter {...ganttFilterProps} />
        </span>
      )}

      {orderVisibility && (
        <span ref={orderRef}>
          <GanttOrder {...ganttOrderProps} />
        </span>
      )}

      {datesVisibility && <span ref={datesRef}>{ganttDates()}</span>}

      {columnsVisibility && (
        <div ref={columnsRef} className="gantt-filter-left-columns">
          <ReactMultiSelectCheckboxes
            {...dataForColumns}
            menuIsOpen={columnsVisibility}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedActivities: getSelectedActivitiesSelector(state),
  showedFilters: getShowedFiltersSelector(state),
  isGanttLoading: getGanttLoadingSelector(state)
});

export default connect(mapStateToProps, {})(GanttFilterHeader);
