/** React components  */
import React, { useState, useEffect, useRef, createRef } from 'react';

import { ganttAPI } from '../../utils/customGanttPlugin';

import { Tooltip, Popover, Button, Icon, notification } from 'antd';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

import { calendarService } from '../../services';

/** Redux */
import { useSelector } from 'react-redux';
import { transformDaysToHours } from '../../views/ganttContainer/gantt/gantt.helper';
import { getTypeNotification, notifyMessageCustom } from '../../utils';
import { MassiveClockComp } from '../GanttMassiveToolbar/icons';
import { TimerManagerSingleton } from '../../utils/timerManager';

/** test id */
export const testIds = {
  RENDER_MASSIVE_DURATION_CONTAINER: 'RENDER_MASSIVE_DURATION_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_MASSIVE_DURATION_CONTAINER}>
    {children}
  </span>
);

export default function GanttMassiveDuration(props) {
  const { tooltipI18nKey, t, selectedActivities, ganttObject, icon } = props;
  const [stateLocal, setStateLocal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const timerManager = TimerManagerSingleton.getInstance();

  const stateProject = useSelector((state) => state.projectState);

  useEffect(() => {
    loadCalendars();
  }, []);

  const loadCalendars = async () => {
    const calendars = await calendarService.showBySector(
      stateProject.sectorSelected
    );
    ganttAPI.loadCalendars(calendars.calendar);
  };

  /** refs */
  const refInputMasssive = createRef();

  /** update massive funcion */
  const massiveDurationHandle = async () => {
    setLoading(true);

    const massiveDurationCallback = () => {
      const newDuration = document.querySelector('#value-mass-duration').value;
      if (isNaN(newDuration) || newDuration.trim() === '') {
        setLoading(false);
        setStateLocal(false);
        return;
      }
      if (selectedActivities.length) {
        const arrParents = [];
        let flagDuration = false;
        selectedActivities.forEach((act) => {
          if (!ganttObject.hasChild(act.id)) {
            const actGet = ganttObject.getTask(act.id);
            if (actGet.progress < 100) {
              if (parseInt(newDuration) === 0) {
                actGet.type = 'milestone';
                actGet.duration = 0;
                actGet.for_disable_milestone_duration = 0;
                actGet.end_date = actGet.start_date;
                actGet.is_milestone = true;
                delete actGet.color;
                ganttObject.autoSchedule(actGet.id);
              } else {
                const resp = ganttAPI.getEndByDuration(
                  actGet.start_date,
                  newDuration,
                  actGet.id,
                  actGet.calendar_id
                );
                if (resp) {
                  actGet.start_date = resp.start_date;
                  actGet.end_date = resp.end_date;
                  actGet.old_masive_duration = actGet.duration;
                  const newDuration = transformDaysToHours(resp.duration);
                  actGet.duration = newDuration;
                  actGet.for_disable_milestone_duration = newDuration;
                  ganttObject.autoSchedule(actGet.id);
                  if (actGet.type === 'milestone') {
                    actGet.type = 'task';
                    actGet.is_milestone = false;
                  }
                  includeParent(actGet.parent, arrParents);
                  ganttObject.updateTask(actGet.id);
                  delete actGet.old_masive_duration;
                }
                flagDuration = true;
              }
            }
          }
        });

        /** processing parents */
        if (arrParents.length) {
          const gantt = window.to_use_react_gantt;
          arrParents.forEach((actId) => {
            const actGet = gantt.getTask(actId);
            const newDuration = ganttAPI.calculateDuration(
              actGet.start_date,
              actGet.end_date,
              actGet.calendar_id,
              gantt
            );
            actGet.duration = newDuration;
            actGet.for_disable_milestone_duration = newDuration;
          });
        }

        ganttObject.optimizedRender();
        ganttObject.ext.keyboardNavigation.focus({
          type: 'taskCell',
          id: selectedActivities[0].id,
          column: 'duration'
        });
        setLoading(false);
        setStateLocal(false);
        if (flagDuration)
          notifyMessageCustom({
            type: getTypeNotification(MassiveClockComp),
            description: t('massive_notif_duration')
          });
      }
    };
    timerManager.registerAutoTimeout(
      massiveDurationCallback,
      300,
      'ganttMassiveDurationTimer'
    );
  };

  const includeParent = (actId, arrParents) => {
    if (!arrParents.includes(actId)) {
      arrParents.push(actId);
    }
    const actGet = window.to_use_react_gantt.getTask(actId);
    if (actGet.parent && actGet.parent !== '0') {
      includeParent(actGet.parent, arrParents);
    }
  };

  const handleVisibleChange = (visible) => {
    setStateLocal(visible);
  };

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  return (
    <span className="gm-duration">
      <TestWrapped>
        <Popover
          onVisibleChange={handleVisibleChange}
          visible={stateLocal}
          overlayClassName="gm-duration-pop"
          placement="bottom"
          content={
            stateLocal ? (
              <div className="wrapper-duration">
                <div className="frm-duration">
                  <div className="row-fields">
                    <span>{t('tables.lookahead.plan.duration')}</span>
                    <NumberFormat
                      id="value-mass-duration"
                      ref={refInputMasssive}
                      className="gm-input-number"
                      defaultValue={0}
                      autoComplete="off"
                      displayType={'input'}
                      style={{ textAlign: 'center' }}
                      autoFocus
                      onFocus={(e) => e.target.select()}
                      isAllowed={(values) => {
                        if (values.value === '') {
                          return true;
                        }
                        const x = parseInt(values.value);
                        if (x >= 0 && x <= 100) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </div>

                  <div className="row-buttons">
                    <Button onClick={massiveDurationHandle} loading={loading}>
                      {t('apply_label')}
                    </Button>
                  </div>
                </div>
              </div>
            ) : null
          }
          trigger="click">
          <Tooltip placement="top" title={t(tooltipI18nKey)}>
            <span
              onClick={(e) => {
                setStateLocal(!stateLocal);
              }}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}>
              {icon && <props.icon color={isHovered ? '#7DFF8A' : '#FFFFFF'} />}
            </span>
          </Tooltip>
        </Popover>
      </TestWrapped>
    </span>
  );
}
