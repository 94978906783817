import React from 'react';
import colors from '../../stylesheets/variables.scss';

/** massive icons */
import massiveTagIconPath from '../../assets/img/icons/icon--massive-tag.svg';
import massiveHelmetIconPath from '../../assets/img/icons/icon--massive-helmet.svg';
import massiveAvatarIconPath from '../../assets/img/icons/icon--massive-avatar.svg';
import massiveCheckIconPath from '../../assets/img/icons/icon--massive-check.svg';
import massiveClockIconPath from '../../assets/img/icons/icon--massive-clock.svg';
import trashIconPath from '../../assets/img/icons/icon--trash.svg';
import massiveDuplicateIconPath from '../../assets/img/icons/icon--massive-dup.svg';
import { SvgIcon } from '../../utils';

export const MassiveClockComp = (
  <SvgIcon
    path={massiveClockIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);

export const MassiveCheckComp = (
  <SvgIcon
    path={massiveCheckIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);

export const MassiveHelmetComp = (
  <SvgIcon
    path={massiveHelmetIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);

export const MassiveAvatarComp = (
  <SvgIcon
    path={massiveAvatarIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);

export const MassiveTagComp = (
  <SvgIcon
    path={massiveTagIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);

export const MassiveDuplicateComp = (
  <SvgIcon
    path={massiveDuplicateIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);

export const TrashComp = (
  <SvgIcon
    path={trashIconPath}
    className="massive-notification-icon"
    color={colors.brandOrange40}
  />
);
