import * as icons from '../../icons';

export const GANTT_LEFT_TOOLBAR = [
  {
    icon: icons.FilterToolbarProjectsIcon,
    command: 'FILTER',
    tooltipI18nKey: 'filters_label.filters_label'
  },
  {
    icon: icons.CalendarToolbarProjectsIcon,
    command: 'DATE',
    tooltipI18nKey: 'master_plan.date'
  },
  {
    icon: icons.ArrowsToolbarProjectsIcon,
    command: 'ORDER',
    tooltipI18nKey: 'filters_label.order_label'
  },
  {
    icon: icons.LayoutToolbarProjectsIcon,
    command: 'COLUMNS',
    tooltipI18nKey: 'filters_label.columns_label'
  }
];
