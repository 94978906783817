import * as icons from '../../icons';
import colors from '../../stylesheets/variables.scss';
import color from '../../assets/styles/variables.scss';
import styles from './GanttToolbar.module.scss';

export const ZOOM_LEVELS = ['DAYS', 'WEEKS', 'MONTH', 'QUARTERS', 'YEARS'];

export const GANTT_LEVELS_CONFIG = {
  icon: icons.GanttLevelsIcon,
  tooltipI18nKey: 'master_plan.visualization_level_tooltip',
  submenuClassName: styles['gantt-toolbar__levels-submenu']
};

export const ZOOM_LEVELS_CONFIG = {
  command: 'SET_ZOOM_LEVEL',
  options: ZOOM_LEVELS.map((level) => ({
    i18nKey: `zoom_levels.${level.toLowerCase()}`,
    value: level
  })),
  defaultValue: JSON.parse(window.localStorage.getItem('scale'))
    ? JSON.parse(window.localStorage.getItem('scale')).toUpperCase()
    : ZOOM_LEVELS[2],
  tooltipI18nKey: 'change_zoom_level'
};

export const TOGGLE_LINKS_VISIBILITY_CONFIG = {
  icon: icons.ShowLinksIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'show_links',
  command: 'TOGGLE_LINKS_VISIBILITY',
  checkable: true
};

export const TOGGLE_SLACK_VISIBILITY_CONFIG = {
  icon: icons.SlackPathsIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'show_slack',
  command: 'TOGGLE_SLACK_VISIBILITY',
  checkable: true
};

export const TOGGLE_BASELINE_VISIBILITY_CONFIG = {
  icon: icons.BaselinesIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'show_baseline',
  command: 'TOGGLE_BASELINE_VISIBILITY',
  checkable: true
};

export const TOGGLE_NUMTASKS_VISIBILITY_CONFIG = {
  icon: icons.NumtasksPathsIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'show_numtasks',
  command: 'TOGGLE_NUMTASKS_VISIBILITY',
  checkable: true
};

export const TOGGLE_TODAYLINE_VISIBILITY_CONFIG = {
  icon: icons.TodaylinePathsIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'show_todayline',
  command: 'TOGGLE_TODAYLINE_VISIBILITY',
  checkable: true
};

export const TOGGLE_SUBMITTALS_VISIBILITY_CONFIG = {
  icon: icons.SubmittalIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'show_submittals',
  command: 'TOGGLE_SUBMITTALS_VISIBILITY',
  checkable: true
};

export const UNDO_REDO_CONFIG = [
  {
    icon: icons.UndoIcon,
    command: 'UNDO',
    tooltipI18nKey: 'master_plan.undo_mov'
  },
  {
    icon: icons.RedoIcon,
    command: 'REDO',
    tooltipI18nKey: 'master_plan.redo_mov'
  }
];

export const INDENT_OUTDENT_CONFIG = [
  {
    icon: icons.OutdentIcon,
    tooltipI18nKey: 'outdent',
    command: 'OUTDENT'
  },
  {
    icon: icons.IndentIcon,
    tooltipI18nKey: 'indent',
    command: 'INDENT'
  }
];

export const LINKS_CONFIG = [
  {
    icon: icons.LinksOnIcon,
    tooltipI18nKey: 'link',
    command: 'LINK'
  },
  {
    icon: icons.LinksOffIcon,
    tooltipI18nKey: 'unlink',
    command: 'UNLINK'
  }
];

export const BAR_COLORS_TITLE = {
  title: true,
  i18nKey: 'bar_colors_title',
  line: true
};

export const COLOR_SCHEME_TYPE_SUBMENU_ITEMS = [
  {
    icon: icons.StatusCircleIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'status_only_label_constraint',
    command: 'CHANGE_COLOR_FOR_STATUS',
    checkable: true
  },
  {
    icon: icons.WorkerIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'settings.subcontracts',
    command: 'CHANGE_COLOR_FOR_SUBCONTRACT',
    checkable: true
  },
  {
    icon: icons.TagIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'config_constraints.tags',
    command: 'CHANGE_COLOR_FOR_TAGS',
    checkable: true
  },
  {
    icon: icons.CriticalPathsIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'show_critical_path',
    command: 'CHANGE_COLOR_FOR_CRITICALPATH',
    checkable: true
  }
];

export const ROW_COLORS_TITLE = {
  title: true,
  i18nKey: 'row_colors_title',
  line: true,
  style: {
    marginTop: 10
  }
};

export const ROW_COLORS_SCHEME_TYPE = [
  {
    icon: icons.iconWBS,
    iconColor: colors.brandBlue40,
    i18nKey: 'wbs_title',
    command: 'CHANGE_COLOR_ROW_FOR_WBS',
    checkable: true
  }
];

export const COLORING_MENU_SETTINGS = [
  BAR_COLORS_TITLE,
  ...COLOR_SCHEME_TYPE_SUBMENU_ITEMS,
  ROW_COLORS_TITLE,
  ...ROW_COLORS_SCHEME_TYPE
];

export const SETTINGS_CONFIG_GANTT = [
  {
    icon: icons.BaselinesIcon,
    command: 'SHOW_BASELINES_MODAL',
    tooltipI18nKey: 'master_plan.saved_baseline_label'
  },
  {
    icon: icons.ScheduleUpdateThinIcon,
    command: 'SHOW_SCHEDULE_MODAL',
    tooltipI18nKey: 'modals.gantt.schedule_updates.schedule_updates'
  },
  GANTT_LEVELS_CONFIG,
  {
    icon: icons.LinesSettingsIcon,
    tooltipI18nKey: 'view_options',
    subitems: [
      TOGGLE_LINKS_VISIBILITY_CONFIG,
      TOGGLE_SLACK_VISIBILITY_CONFIG,
      TOGGLE_NUMTASKS_VISIBILITY_CONFIG,
      TOGGLE_BASELINE_VISIBILITY_CONFIG,
      TOGGLE_TODAYLINE_VISIBILITY_CONFIG,
      TOGGLE_SUBMITTALS_VISIBILITY_CONFIG
    ]
  },
  {
    icon: icons.ColorsIcon,
    command: 'SHOW_BAR_ROW_COLOR',
    tooltipI18nKey: 'bar_colors',
    direction: 'LEFT',
    submenuTableSection: true,
    subitemsSettings: COLORING_MENU_SETTINGS
  }
];

export const EXPORT_CONFIG_OBJECT = {
  icon: icons.BlankDocumentIcon,
  direction: 'LEFT',
  tooltipI18nKey: 'import_and_download',
  command: 'IMPORT_AND_DOWNLOAD',
  subitems: [
    {
      icon: icons.UploadIcon,
      iconColor: colors.greenIcon,
      i18nKey: 'master_plan.import_new_master',
      command: 'IMPORT_SCHEDULE'
    },
    {
      icon: icons.DownloadIcon,
      iconColor: colors.greenIcon,
      i18nKey: 'master_plan.download_master',
      subitems: [
        {
          icon: icons.ExcelIcon,
          iconColor: colors.greenIcon,
          text: 'Excel',
          command: 'DOWNLOAD_SCHEDULE_TO_EXCEL'
        },
        {
          icon: icons.PdfIcon,
          iconColor: colors.greenIcon,
          text: 'PDF',
          command: 'DOWNLOAD_SCHEDULE_TO_PDF'
        },
        {
          icon: icons.XmlIcon,
          iconColor: colors.greenIcon,
          text: 'MS Project (XML)',
          command: 'DOWNLOAD_SCHEDULE_TO_XML'
        },
        {
          icon: icons.P6Icon,
          iconColor: colors.greenIcon,
          text: 'Primavera P6 (XML)',
          command: 'DOWNLOAD_SCHEDULE_TO_P6_XML',
          isBeta: true,
          hidden: false
        }
      ]
    }
  ]
};

export const MR_CONFIG_OBJECT = {
  icon: icons.ModificationRequestIcon,
  command: 'SHOW_MODIFICATION_REQUESTS_DRAWER',
  tooltipI18nKey: 'modification_requests_drawer.title',
  visible: true
};

export const BTN_SAVE_CONFIG = {
  icon: icons.SaveIcon,
  iconColor: color.black,
  i18nKey: 'master_plan_resources.save',
  isCta: true,
  command: 'SAVE'
};

export const SCROLL_CONFIG = {
  icon: icons.PinToolbarProjectsIcon,
  command: 'SCROLL_TO_TODAY',
  tooltipI18nKey: 'scroll_to_today'
};

export const TOOLBAR_RIGHT_GROUP_ITEMS = [
  SCROLL_CONFIG,
  ZOOM_LEVELS_CONFIG,
  UNDO_REDO_CONFIG,
  INDENT_OUTDENT_CONFIG,
  LINKS_CONFIG,
  SETTINGS_CONFIG_GANTT,
  EXPORT_CONFIG_OBJECT,
  MR_CONFIG_OBJECT,
  BTN_SAVE_CONFIG
];
