/** React components  */
import React, { useState, useEffect, useRef, createRef } from 'react';

import { ganttAPI } from '../../utils/customGanttPlugin';
import Range from 'react-range-progress';
import { Tooltip, Popover, Slider } from 'antd';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

import { calendarService } from '../../services';

/** Redux */
import { useSelector } from 'react-redux';
import useModalBasic from '../../hooks/useModalBasic';
import { MassiveCheckComp } from '../GanttMassiveToolbar/icons';
import { getTypeNotification, notifyMessageCustom } from '../../utils/';
import { TimerManagerSingleton } from '../../utils/timerManager';

/** test id */
export const testIds = {
  RENDER_MASSIVE_PROGRESS_CONTAINER: 'RENDER_MASSIVE_PROGRESS_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_MASSIVE_PROGRESS_CONTAINER}>
    {children}
  </span>
);

export default function GanttMassiveProgress(props) {
  const { tooltipI18nKey, t, selectedActivities, ganttObject } = props;
  const [stateLocal, setStateLocal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [progress, setProgress] = useState(0);

  /** redux */
  const stateProject = useSelector((state) => state.projectState);
  const timerManager = TimerManagerSingleton.getInstance();

  /** use custom hook for progress alert */
  const message = t('master_plan.dup_warning');
  const contentModalProgressMassive = {
    customWidth: 430,
    message: message,
    question_i18: null,
    title_i18: null,
    text_ok_i18: 'confirmation.continue',
    hideCancelButton: false,
    hideOkButton: false
  };
  const { jsxModalProgressMass, callbackModalProgressMass } = useModalBasic(
    t,
    contentModalProgressMassive,
    'ProgressMass'
  );

  /**
   * This function opens the modal that displays the warning on bulk action of duplicate
   */
  const modalProgressMass = (n) => {
    const message = t('master_plan.progress_warning').replace(
      '__n__',
      '<span>' + n + '</span>'
    );

    /** matrix to give css to the number of activities (n) */
    const msgArr = [];
    msgArr.push(message.split('(')[0]);
    msgArr.push(n);
    msgArr.push(message.split(')')[1]);
    callbackModalProgressMass &&
      callbackModalProgressMass(processSetProgress, msgArr);
  };

  useEffect(() => {
    loadCalendars();
  }, []);

  useEffect(() => {
    if (!stateLocal) setProgress(0);
  }, [stateLocal]);

  const loadCalendars = async () => {
    const calendars = await calendarService.showBySector(
      stateProject.sectorSelected
    );
    ganttAPI.loadCalendars(calendars.calendar);
  };

  const processSetProgress = () => {
    setStateLocal(false);
    let newProgress = parseInt(progress);

    /** validate empty progress */
    if (isNaN(newProgress)) {
      newProgress = 0;
      setProgress(newProgress);
    }
    if (selectedActivities.length) {
      let flagUpdate = false;
      selectedActivities.forEach((act) => {
        if (!ganttObject.hasChild(act.id)) {
          /** only apply to child activitys */
          const actGet = ganttObject.getTask(act.id);
          if (
            (actGet.tasks && !actGet.tasks.length) ||
            actGet.type === 'milestone' ||
            actGet.tasks === undefined
          ) {
            /** only apply to activitys without tasks or milestone */
            actGet.progress = newProgress;
            ganttObject.updateTask(actGet.id);
            flagUpdate = true;
          }
        }
      });
      const massiveProgressHandle = () => {
        ganttObject.updateExpectedGlobal();
        ganttObject.executeAutoScheduleExternal();
        ganttObject.changeVisualizationOption &&
          ganttObject.changeVisualizationOption();
      };

      timerManager.registerAutoTimeout(
        massiveProgressHandle,
        500,
        'massiveProgressHandle'
      );

      ganttObject.render();
      ganttObject.ext.keyboardNavigation.focus({
        type: 'taskCell',
        id: selectedActivities[0].id,
        column: 'progress'
      });
      if (flagUpdate)
        notifyMessageCustom({
          type: getTypeNotification(MassiveCheckComp),
          description: t('massive_notif_progress')
        });
    }
  };

  /** update massive funcion */
  const massiveProgressHandle = () => {
    let contActivitieswithTasks = 0;
    if (selectedActivities.length) {
      selectedActivities.forEach((act) => {
        if (act.tasks && act.tasks.length) {
          contActivitieswithTasks++;
        }
      });
    }
    if (contActivitieswithTasks) {
      modalProgressMass(contActivitieswithTasks);
      setStateLocal(false);
    } else {
      processSetProgress();
    }
  };

  const handleVisibleChange = (visible) => {
    setStateLocal(visible);
  };

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  return (
    <span className="gm-progress">
      <TestWrapped>
        <Popover
          onVisibleChange={handleVisibleChange}
          visible={stateLocal}
          overlayClassName="gm-progress-pop"
          placement="bottom"
          content={
            stateLocal ? (
              <div className="wrapper-progress">
                <div className="frm-progress">
                  <div className="row-fields">
                    <span>{t('tables.lookahead.plan.progress')}</span>
                    <NumberFormat
                      className="gm-input-number"
                      value={progress}
                      defaultValue={0}
                      autoComplete="off"
                      displayType={'input'}
                      style={{ textAlign: 'center' }}
                      autoFocus
                      onFocus={(e) => {
                        const selectCallback = () => {
                          e.target.select();
                        };
                        timerManager.registerAutoTimeout(
                          selectCallback,
                          300,
                          'progressFocus'
                        );
                      }}
                      onChange={(e) => setProgress(parseInt(e.target.value))}
                      suffix={'%'}
                      isAllowed={(values) => {
                        if (values.value === '') {
                          return true;
                        }
                        const x = parseInt(values.value);
                        if (x >= 0 && x <= 100) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </div>

                  <div className="row-fields progress-bar">
                    <span style={{ width: '100%' }}>
                      <Range
                        className="progress-massive-bar"
                        style={{ top: -2 }}
                        value={progress}
                        fillColor={{
                          r: 245,
                          g: 157,
                          b: 4,
                          a: 1
                        }}
                        trackColor={{
                          r: 245,
                          g: 245,
                          b: 245,
                          a: 1
                        }}
                        height={4}
                        hideThumb={false}
                        width="100%"
                        onChange={(value) => setProgress(parseInt(value))}
                      />
                    </span>
                  </div>

                  <div className="row-buttons">
                    <div onClick={massiveProgressHandle}>
                      {t('apply_label')}
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
          trigger="click">
          <Tooltip placement="top" title={t(tooltipI18nKey)}>
            <span
              onClick={(e) => {
                setStateLocal(!stateLocal);
              }}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}>
              {props.icon && (
                <props.icon color={isHovered ? '#7DFF8A' : '#FFFFFF'} />
              )}
            </span>
          </Tooltip>
        </Popover>
        {/* Progress Massive */}
        {jsxModalProgressMass}
      </TestWrapped>
    </span>
  );
}
