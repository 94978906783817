import {
  SettingsIcon,
  CalendarIcon,
  ProgressCurveCriteriaIcon,
  InfoIcon,
  DateFormatIcon,
  StatusCriteriaIcon,
  MoreUserIcon
} from '../../icons';
import { isFeatureOn } from '../../utils/featureUtils';
import colors from '../../stylesheets/variables.scss';
import { FEATURE_FLAGS } from '../../constants/featureFlags';

const DATE_FORMATS = [
  'DD/MM/YY',
  'MM/DD/YY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'DD/MM/YY hh:mm',
  'MM/DD/YY hh:mm'
];

export const regexDateFormat = {
  'DD/MM/YY': /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{2}$/,
  'MM/DD/YY': /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{2}$/,
  'DD/MM/YYYY': /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/,
  'MM/DD/YYYY': /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
  'DD/MM/YY hh:mm':
    /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{2} (?:[01]\d|2[0-3]):[0-5]\d$/,
  'MM/DD/YY hh:mm':
    /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{2} (?:[01]\d|2[0-3]):[0-5]\d$/
};

const CRITERIA_STATUS_OPTIONS = ['Baseline', 'Follow'];

export const MORE_INFO_CONFIG = {
  icon: InfoIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'more_info',
  command: 'MORE_INFO'
};

export const DATE_FORMAT_CONFIG = {
  icon: DateFormatIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'date_format',
  subitems: DATE_FORMATS.map((format) => ({ text: format, checkable: true }))
};

/** constant criteria */
export const CRITERIA_STATUS_CONFIG = {
  icon: StatusCriteriaIcon,
  iconColor: colors.greenIcon,
  i18nKey: 'status_criteria',
  subitems: CRITERIA_STATUS_OPTIONS.map((criteria, i) => ({
    i18nKey: 'status_criteria_' + criteria,
    checkable: true,
    command: `SET_CRITERIA_STATUS_${criteria}`
  }))
};

export const GANTT_SETTINGS_ITEMS = [
  {
    icon: MoreUserIcon,
    iconColor: colors.gray80,
    direction: 'LEFT',
    tooltipI18nKey: 'modals.add_users_modals.add_users_tooltip',
    command: 'GANTT_ADD_USERS_ITEMS',
    i18nKey: 'modals.add_users_modals.add_users_btn',
    invertTextColor: true,
    subitems: [
      {
        iconColor: colors.greenIcon,
        i18nKey: 'modals.add_users_modals.create_new_user_option',
        command: 'OPEN_ADD_USERS_MODAL'
      },
      {
        iconColor: colors.greenIcon,
        i18nKey: 'modals.add_users_modals.assign_existing_users_option',
        command: 'OPEN_ASSIGN_USERS_MODAL'
      }
    ]
  },
  {
    icon: SettingsIcon,
    iconColor: colors.gray80,
    direction: 'LEFT',
    tooltipI18nKey: 'settings.settings',
    command: 'GANTT_SETTINGS_ITEMS',
    subitems: [
      {
        icon: CalendarIcon,
        iconColor: colors.greenIcon,
        i18nKey: 'master_plan.calendars_label',
        command: 'NAVIGATE_TO_CALENDARS'
      },
      DATE_FORMAT_CONFIG,
      CRITERIA_STATUS_CONFIG,
      ...(isFeatureOn(FEATURE_FLAGS.ENABLE_NEW_SETTINGS_MENU_ITEMS)
        ? [
            {
              icon: ProgressCurveCriteriaIcon,
              iconColor: colors.brandBlue40,
              i18nKey: 'project_config.curve_criteria',
              command: 'OPEN_PROGRESS_CURVE_CRITERIA_MODAL'
            }
          ]
        : [MORE_INFO_CONFIG])
    ]
  }
];
