import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ganttActions from '../../redux/slices/ganttSlice';
import closeImg from '../../assets/img/order-esc.png';
import arrowSelectImg from '../../assets/img/groupping-arrow.png';
import { transformHourToDays } from '../../views/ganttContainer/gantt/gantt.helper';
import {
  Switch,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Select,
  Input,
  InputNumber,
  DatePicker
} from 'antd';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './index.css';
import './ganttFilter.scss';
import { isActiveFilter } from '../../utils/filterUtils';
import { isColumnAvailableForRole } from '../../utils/partialPermissionUtils';
import { TimerManagerSingleton } from '../../utils/timerManager';

const { Option } = Select;
/** DONT DELETE, NOT DEAD CODE, THIS IS USED BY EVAL JS FUNCTION */
const _Trans = transformHourToDays;
if (typeof window !== 'undefined') {
  window._Trans = _Trans;
}

export const testIds = {
  RENDER_GANTT_FILTER_FILTER: 'RENDER_GANTT_FILTER_FILTER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_GANTT_FILTER_FILTER}>{children}</span>
);

/**
 * This component allows users to filter Gantt Data through a dinamic filter opts
 * @param {*} props Props must receive a { options, gantt }
 */
const GanttFilter = (props) => {
  const {
    t,
    showedFilters,
    setShowedFilters,
    andFilter,
    setAndFilter,
    optionsArray,
    selectedOption
  } = props;
  const timerManager = TimerManagerSingleton.getInstance();
  // checkBackgrondFilter, options, gantt, isSubDropActive
  const [addFilterOptions, setAddFilterOptions] = useState(
    []
  ); /** Array with all options to add filters */
  const ganttState = useSelector((state) => state.ganttState);
  const dispatch = useDispatch();

  /**
   * This hook works to build options to add filters dropdown
   */

  useEffect(() => {
    if (showedFilters.value.length != 0) {
      filterGanttData();
    }
  }, [ganttState.notifyChangeFilter]);

  useEffect(() => {
    if (ganttState.isRangeDateFiltered) {
      showedFilters.value = [];
      updateRender();
      // filterGanttData()
    }
  }, [ganttState.isRangeDateFiltered]);

  useEffect(() => {
    const opts = (
      <Menu className="columns-list">
        {props.options.map((op, index) => {
          if (op.filterable && isColumnAvailableForRole(op.name)) {
            return (
              <Menu.Item key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => addNewFilter(op)}>
                  {op.label}
                </a>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    setAddFilterOptions(opts);
  }, [
    showedFilters
  ]); /** If there are DOM elements on the JSX, associated with other useState hooks, needs to be updated to fit with the same STATE */

  /**
   * This function handle the and/or filter
   * @param {*} checked Boolean value
   * @param {*} event Event on press
   */
  const onChangeAndSwitch = (checked, event) => {
    andFilter.value = checked;
    updateRender();
    filterGanttData();
  };

  /**
   * This function handle add new filters to the dinamic list
   * @param {*} filter Specific filter coming from props.options
   */
  const addNewFilter = (filter) => {
    // eslint-disable-next-line no-self-assign
    andFilter.value = andFilter.value;
    if (filter.data_type == 'string') {
      filter.equals = 'includes';
    } else if (filter.data_type == 'date') {
      filter.equals = 'afterthan';
    } else if (filter.data_type == 'number') {
      filter.equals = 'equals';
    } else if (filter.data_type.includes('array/')) {
      filter.equals = 'issomeof';
    } else {
      filter.equals = false;
    }
    filter.filter_by = '';
    showedFilters.value.push(filter);
    updateRender();
    filterGanttData();
  };

  /**
   * This function handle delete icon no single filter activated
   * @param {*} filter Specific filter coming from props.options
   */
  const deleteFilter = (filter) => {
    const n = showedFilters.value.filter((op) => {
      if (op != filter) return true;
      return false;
    });
    showedFilters.value = n;

    updateRender();
    filterGanttData();
  };

  /**
   * This function handle is/isnt option from single filter activated
   * @param {*} checked Boolean value
   * @param {*} event Event from on click
   * @param {*} filter Specific filter coming from props.options
   */
  const onChangeIsFilter = (checked, event, filter) => {
    filter.equals = checked;
    updateRender();
    filterGanttData();
  };

  const focusMissingElement = (idToFocusThen) => {
    if (idToFocusThen) {
      const el = document.getElementById(idToFocusThen);
      if (el) {
        el.focus();
      }
    }
  };

  /**
   * This function handle filter_by attr comming from single filter activated
   * @param {*} e event from input
   * @param {*} filter Specific filter coming from props.options
   */
  const onChangeFilterBy = (e, filter, idToFocusThen = false) => {
    const { value } = e.target;
    filter.filter_by = value;
    updateRender();
    filterGanttData();
    idToFocusThen
      ? focusMissingElement(idToFocusThen)
      : console.log('No id to focus');
  };

  const onNumberInputChange = (value, filter, idToFocusThen = false) => {
    filter.filter_by = value;
    updateRender();
    filterGanttData();
    idToFocusThen
      ? focusMissingElement(idToFocusThen)
      : console.log('No id to focus');
  };

  const onDateInputChange = (
    date,
    dateString,
    filter,
    idToFocusThen = false
  ) => {
    filter.filter_by = dateString;
    updateRender();
    filterGanttData();
    idToFocusThen
      ? focusMissingElement(idToFocusThen)
      : console.log('No id to focus');
  };

  /**
   * This functions allow developer to re load on force the render (virtual DOM)
   */
  const updateRender = () => {
    const a = JSON.stringify(showedFilters);
    const b = JSON.stringify(andFilter);
    setShowedFilters(JSON.parse(a));

    setAndFilter((prev) => {
      prev = JSON.parse(b);
      return prev;
    });
  };

  const onSelectSingleChange = (val, filter) => {
    filter.filter_by = val;
    updateRender();
    filterGanttData();
  };

  const getGrandParent = (childId, array) => {
    const parentId = props.gantt.getParent(childId);
    if (parentId) {
      array.push(parentId);
      getGrandParent(parentId, array);
    }
  };

  const showParents = (showedActivities) => {
    const parentToshow = [];
    showedActivities.map((showedId) => {
      getGrandParent(showedId, parentToshow);
    });
    const uniqueParents = [...new Set(parentToshow)];
    uniqueParents.map((parentId) => {
      let object;
      if (parentId != '0' && parentId != 0) {
        object = props.gantt.getTask(parentId);
        if (object) {
          object.should_be_showed = true;
        }
      }
    });
  };

  const filterGanttData = () => {
    if (showedFilters.value.length != 0) {
      dispatch(ganttActions.setIsRangeFiltered({ boolean: false, range: [] }));
      props.gantt.isDataFiltered = true;

      let query = '';
      const connector = andFilter.value ? ' || ' : ' && ';
      let activeFilter = false;
      showedFilters.value.map((filter, index) => {
        const isLast = index == showedFilters.value.length - 1;
        let value = "'" + filter.filter_by + "'";
        activeFilter = isActiveFilter(filter);

        if (filter.filter_by === 100) {
          value = "'" + filter.filter_by + "'";
        } else if (
          filter.filter_by <= '100' &&
          filter.filter_by >= '99.9999999999'
        ) {
          value = "'99'";
        }

        let valueToFilter = 'el.' + filter.name;

        if (filter.data_type == 'date') {
          if (value) {
            switch (filter.equals) {
              case 'afterthan':
                /** Despues de la fecha indicada */
                query =
                  query +
                  '(new Date(' +
                  valueToFilter +
                  ')).getTime() > (new Date(' +
                  value +
                  ')).getTime()';
                break;
              case 'beforethan':
                query =
                  query +
                  '(new Date(' +
                  valueToFilter +
                  ')).getTime() < (new Date(' +
                  value +
                  ')).getTime()';
                break;
            }
          }
        } else if (filter.data_type == 'string') {
          switch (filter.equals) {
            case 'includes':
              query =
                String(query) +
                valueToFilter +
                '.toLocaleLowerCase().includes(' +
                value.toLocaleLowerCase() +
                ')';
              break;
            case 'notincludes':
              query =
                query +
                '!' +
                valueToFilter +
                '.toLocaleLowerCase().includes(' +
                value.toLocaleLowerCase() +
                ')';
              break;
            case 'is':
              query = String(query) + valueToFilter + ' == ' + value;
              break;
            case 'isnot':
              query = String(query) + valueToFilter + ' != ' + value;
              break;
          }
        } else if (filter.data_type == 'number') {
          if (
            filter.name == 'duration' ||
            filter.name == 'totalSlack' ||
            filter.name == 'freeSlack'
          ) {
            valueToFilter = 'window._Trans(el.' + filter.name + ')';
          }

          switch (filter.equals) {
            case 'equals':
              query =
                String(query) + valueToFilter + ' == parseFloat(' + value + ')';
              break;
            case 'notequals':
              query =
                String(query) + valueToFilter + ' != parseFloat(' + value + ')';
              query =
                query +
                ' && ' +
                valueToFilter +
                ' != parseFloat(99.9999999999999)';
              break;
            case 'morethan':
              query =
                String(query) + valueToFilter + ' > parseFloat(' + value + ')';
              break;
            case 'lessthan':
              query =
                String(query) + valueToFilter + ' < parseFloat(' + value + ')';
              break;
            case 'moreequalsthan':
              query =
                String(query) + valueToFilter + ' >= parseFloat(' + value + ')';
              break;
            case 'lessequalsthan':
              query =
                String(query) + valueToFilter + ' <= parseFloat(' + value + ')';
              break;
          }
        } else if (filter.data_type == 'array/images') {
          const value =
            valueToFilter == 'el.user' ? `el.${filter.ref}` : valueToFilter;
          switch (filter.equals) {
            case 'issomeof':
              query =
                query +
                `(${value}.filter( u =>
                            ${JSON.stringify(filter.filter_by)}.includes(u.${filter.el_to_extract_from})).length)`;
              break;
            case 'isnotsomeof':
              query =
                query +
                `!(${value}.filter( u =>
                            ${JSON.stringify(filter.filter_by)}.includes(u.${filter.el_to_extract_from})).length)`;
              break;
          }
        } else if (filter.data_type != 'array/images') {
          /** Mixed cols logic */
          let toAddQueryMixed = [];
          let mixQueries = true;
          if (filter.mixed) {
            let howManyMixDoesInclude = 0;
            if (filter.filter_by.map) {
              filter.filter_by.map((by) => {
                if (filter.mixed_from.includes(by)) {
                  howManyMixDoesInclude++;
                  if (filter.equals == 'issomeof') {
                    toAddQueryMixed.push(' el.' + by);
                  } else if (filter.equals == 'isnotsomeof') {
                    toAddQueryMixed.push(' !el.' + by);
                  }
                }
              });
              toAddQueryMixed = toAddQueryMixed.join(' && ');

              if (filter.filter_by.length == howManyMixDoesInclude) {
                mixQueries = false;
              }
            }
          }

          switch (filter.equals) {
            case 'issomeof':
              if (filter.name == 'calendar_id') {
                query =
                  query +
                  value +
                  '.split(",").includes(JSON.stringify(' +
                  valueToFilter +
                  '))';
              } else if (mixQueries) {
                query =
                  query +
                  value +
                  '.split(",").map(elem => isNaN(elem)? elem : parseInt(elem)).includes(' +
                  valueToFilter +
                  ')';
              }

              if (toAddQueryMixed.length) {
                query = query + (mixQueries ? ' && ' : '') + toAddQueryMixed;
              }

              break;
            case 'isnotsomeof':
              if (mixQueries) {
                query =
                  query +
                  '!' +
                  value +
                  '.split(",").map(elem => isNaN(elem)? elem : parseInt(elem)).includes(' +
                  valueToFilter +
                  ')';
              }

              if (toAddQueryMixed.length) {
                query = query + (mixQueries ? ' &&' : '') + toAddQueryMixed;
              }
              break;
          }
        }

        if (!isLast) {
          query = query + connector;
        }
      });
      const tasks = props.gantt.getTaskByTime();
      const showedActivities = [];
      if (activeFilter) {
        tasks.filter((el) => {
          /** If a activity has child will not be filtered */
          // if (window.to_use_react_gantt.hasChild(el.id)) {
          //     el.should_be_showed = true
          //     return
          // }
          // eslint-disable-next-line no-eval
          if (eval(query)) {
            el.should_be_showed = true;
            showedActivities.push(el.id);
          } else {
            el.should_be_showed = false;
          }
        });
        showParents(showedActivities);
      } else {
        props.gantt.isDataFiltered = false;
        tasks.filter((el) => {
          // eslint-disable-next-line no-eval
          el.should_be_showed = true;
        });
      }
    } else {
      props.gantt.isDataFiltered = false;
      const tasks = props.gantt.getTaskByTime();
      tasks.filter((el) => {
        // eslint-disable-next-line no-eval
        el.should_be_showed = true;
      });
    }

    props.gantt.refreshData();
    props.gantt.render();
    // go to start
    props.gantt.scrollTo(null, 0);
  };

  const renderShowedFilters = () =>
    showedFilters.value.map((filter, index) => {
      let inputFilter;
      let filterableSelect;
      const idUnique = filter.data_type + '-' + index;

      if (filter.data_type == 'string') {
        inputFilter = (
          <Input.Search
            id={idUnique}
            value={filter.filter_by}
            onChange={(e) => onChangeFilterBy(e, filter, idUnique)}
            size="small"
            className="gantt-header-search-bar custom-search-bar-prop"
            placeholder=""
          />
        );

        filterableSelect = (
          <Select
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option value="includes">
              {t('filters_label.string_data.includes')}
            </Option>
            <Option value="notincludes">
              {t('filters_label.string_data.notincludes')}
            </Option>
            <Option value="is">{t('filters_label.string_data.is')}</Option>
            <Option value="isnot">
              {t('filters_label.string_data.isnot')}
            </Option>
          </Select>
        );
      } else if (filter.data_type == 'number') {
        inputFilter = (
          <InputNumber
            type="number"
            id={idUnique}
            value={filter.filter_by}
            onChange={(e) => onNumberInputChange(e, filter, idUnique)}
            size="small"
            className="gantt-header-search-bar custom-search-bar-prop"
            placeholder=""
          />
        );

        filterableSelect = (
          <Select
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option value="equals">=</Option>
            <Option value="notequals">≠</Option>
            <Option value="morethan">{'>'}</Option>
            <Option value="lessthan">{'<'}</Option>
            <Option value="moreequalsthan">≥</Option>
            <Option value="lessequalsthan">≤</Option>
          </Select>
        );
      } else if (filter.data_type == 'date') {
        inputFilter = (
          <DatePicker
            id={idUnique}
            onChange={(date, dateString) =>
              onDateInputChange(date, dateString, filter, idUnique)
            }
            size="small"
            className="gantt-header-search-bar"
            placeholder=""
          />
        );

        filterableSelect = (
          <Select
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option value="afterthan">
              {t('filters_label.date_data.afterthan')}
            </Option>
            <Option value="beforethan">
              {t('filters_label.date_data.beforethan')}
            </Option>
          </Select>
        );
      } else if (filter.data_type.includes('array/')) {
        if (filter.data_type.includes('string')) {
          if (!optionsArray[filter.name]) {
            optionsArray[filter.name] = [];
            filter.from_values.map((el, index) => {
              optionsArray[filter.name].push({
                label: el.label,
                value: el.value
              });
            });
          }
          inputFilter = (
            <ReactMultiSelectCheckboxes
              onMenuClose={() => {
                props.isSubDropActive.value = false;
              }}
              onMenuOpen={() => {
                props.isSubDropActive.value = true;
              }}
              placeholder={t('master_plan.search')}
              className="filter-lookahead-multi-check"
              getDropdownButtonLabel={() => (
                <div
                  style={{
                    color: '#121212',
                    fontSize: '12px',
                    height: '38px',
                    paddingRight: '32px'
                  }}
                  onClick={() => {
                    props.isSubDropActive.value = true;
                    const updateContainerCallback = () => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      a[0].parentNode.style.marginTop = '0px';
                    };
                    timerManager.registerAutoTimeout(
                      updateContainerCallback,
                      20,
                      'renderShowedFilters'
                    );
                  }}>
                  <span className="select-custom-checkbox-filter-string">
                    {t('filter_by_label')}
                  </span>
                  <img
                    src={arrowSelectImg}
                    className="select-custom-checkbox-filter-arrow"></img>
                </div>
              )}
              onChange={(e) => {
                props.isSubDropActive.value = true;
                selectedOption.value = e;
                const data = e.map((el) => el.value);
                onSelectSingleChange(data, filter);
              }}
              options={optionsArray[filter.name]}
            />
          );
        } else if (filter.data_type.includes('icon')) {
          if (!optionsArray[filter.name]) {
            optionsArray[filter.name] = [];
            filter.from_values.map((el, index) => {
              optionsArray[filter.name].push({
                label: el.label,
                value: el.value
              });
            });
          }
          inputFilter = (
            <ReactMultiSelectCheckboxes
              onMenuClose={() => {
                props.isSubDropActive.value = false;
              }}
              onMenuOpen={() => {
                props.isSubDropActive.value = true;
              }}
              placeholder={t('master_plan.search')}
              className="filter-lookahead-multi-check"
              getDropdownButtonLabel={() => (
                <div
                  style={{
                    color: '#121212',
                    fontSize: '12px',
                    height: '38px',
                    paddingRight: '32px'
                  }}
                  onClick={() => {
                    props.isSubDropActive.value = true;
                    const updateContainerCallback = () => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      a[0].parentNode.style.marginTop = '0px';
                    };
                    timerManager.registerAutoTimeout(
                      updateContainerCallback,
                      20,
                      'getDropdownButtonLabelClick'
                    );
                  }}>
                  <span className="select-custom-checkbox-filter-string">
                    {t('filter_by_label')}
                  </span>
                  <img
                    src={arrowSelectImg}
                    className="select-custom-checkbox-filter-arrow"></img>
                </div>
              )}
              onChange={(e) => {
                selectedOption.value = e;
                props.isSubDropActive.value = true;
                const data = e.map((el) => el.value);
                onSelectSingleChange(data, filter);
              }}
              options={optionsArray[filter.name]}
            />
          );
        } else if (filter.data_type.includes('images')) {
          if (!optionsArray[filter.name]) {
            optionsArray[filter.name] = [];
            props[filter.from_values].map((from, index) => {
              let label = from[filter.el_to_label_from[0]];
              if (filter.el_to_label_from[1]) {
                label += ' ' + from[filter.el_to_label_from[1]];
              }
              optionsArray[filter.name].push({
                label: label,
                value: from[filter.el_to_extract_from]
              });
            });
          }
          inputFilter = (
            <ReactMultiSelectCheckboxes
              onMenuClose={() => {
                props.isSubDropActive.value = false;
              }}
              onMenuOpen={() => {
                props.isSubDropActive.value = true;
              }}
              placeholder={t('master_plan.search')}
              className="filter-lookahead-multi-check"
              getDropdownButtonLabel={() => (
                <div
                  style={{
                    color: '#121212',
                    fontSize: '12px',
                    height: '38px',
                    paddingRight: '32px'
                  }}
                  onClick={() => {
                    props.isSubDropActive.value = true;
                    const updateContainerCallback = () => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      a[0].parentNode.style.marginTop = '0px';
                    };
                    timerManager.registerAutoTimeout(
                      updateContainerCallback,
                      20,
                      'getDropdownButtonLabel'
                    );
                  }}>
                  <span className="select-custom-checkbox-filter-string">
                    {t('filter_by_label')}
                  </span>
                  <img
                    src={arrowSelectImg}
                    className="select-custom-checkbox-filter-arrow"></img>
                </div>
              )}
              onChange={(e) => {
                props.isSubDropActive.value = true;
                selectedOption.value = e;
                const data = e.map((el) => el.value);
                onSelectSingleChange(data, filter);
              }}
              options={optionsArray[filter.name]}
            />
          );
        }

        filterableSelect = (
          <Select
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option value="issomeof">
              {t('filters_label.array_data.issomeof')}
            </Option>
            <Option value="isnotsomeof">
              {t('filters_label.array_data.isnotsomeof')}
            </Option>
          </Select>
        );
      }

      return (
        <Col key={index}>
          <Row className="single-filter-row">
            <Col
              onClick={() => deleteFilter(filter)}
              className="delete-filter-style"
              span={1}>
              <img
                src={closeImg}
                width={6}
                style={{ position: 'relative', top: -2 }}
              />
            </Col>
            <Col span={2}>{t('filters_label.where_label')}</Col>
            <Col span={6} offset={1}>
              {filter.label}
            </Col>
            <Col
              span={8}
              style={{ maxHeight: 27, overflow: 'hidden', marginLeft: -27 }}>
              {filterableSelect}
            </Col>
            <Col span={6} style={{ maxHeight: 29 }}>
              {inputFilter}
            </Col>
          </Row>
        </Col>
      );
    });

  const checkIfFiltersAreActive = () => {
    if (showedFilters.value.length) {
      return renderShowedFilters();
    }
    return (
      <div className="non-selected-order">
        {t('master_plan.no_fields_filter_selected')}
      </div>
    );
  };

  return (
    <TestWrapped>
      <div className="filter-main-container gantt-filter-left-filter">
        <Row className="full-width-style">
          <Col className="switch-col-style">
            <span style={{ marginRight: 5 }}>
              {t('filters_label.and_label')}
            </span>
            <Switch
              onChange={(checked, event) => onChangeAndSwitch(checked, event)}
              checked={andFilter.value}
              size="small"
            />
            <span style={{ marginLeft: 5 }}>{t('filters_label.or_label')}</span>
          </Col>
        </Row>
        <Row className="full-width-style">{checkIfFiltersAreActive()}</Row>
        <Row className="full-width-style">
          <Col className="switch-col-style">
            <Dropdown overlay={addFilterOptions} trigger={['click']}>
              <a
                onClick={(e) => e.preventDefault()}
                style={{ color: '#53C255', marginLeft: 3 }}>
                <Icon type="plus" style={{ color: '#53C255' }} />
                {t('master_plan.add_fields_filter')}
              </a>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </TestWrapped>
  );
};

export default GanttFilter;
